import * as React from 'react';
import { LinkItem, MenuGroup, Section } from '@atlaskit/menu';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function SettingMenu() {
  const { t } = useTranslation();
  const location = useLocation();

  // Define an array of settings with their paths and titles
  const settings = [
    { path: '/settings/teamProfile', title: t('settings.settingsRouter.cp') },
    { path: '/settings/teamSMTP', title: t('settings.settingsRouter.cs') },
    { path: '/settings/language', title: t('settings.settingsRouter.language') },
    // { path: '/settings/teamSellers', title: 'Company Sellers' },
    // { path: '/settings/', title: 'Company Profile' },
  ];

  return (
    <MenuGroup>
      <Section title={t('settings.title')}>
        {settings.map((setting) => (
          <LinkItem
            key={setting.path}
            href={setting.path}
            isSelected={location.pathname === setting.path}
          >
            {setting.title}
          </LinkItem>
        ))}
      </Section>
    </MenuGroup>
  );
}
