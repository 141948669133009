import React from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
// types
import Flag from '@atlaskit/flag';
import { colors } from '@atlaskit/theme';
import InfoIcon from '@atlaskit/icon/glyph/info';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import { SearchResultProps } from './types';
// local component
import ProductDetails from './ProductDetails';
import Nofound from '../Nofound';
import Products from './Products';
import Wrapper from '../ListView/Wrapper';
import { useProductSeedsQuery } from '../../graphql/types';
import SynnexProducts from './SynnexProducts';

export default (props: SearchResultProps) => {
  const { t } = useTranslation();
  const {
    data: newData, onComplete, inputValue, onSelectProductCategory,
  } = props;

  const { data } = useProductSeedsQuery({
    variables: {
      keyword: inputValue,
    },
  });

  const [epid, setEpid] = React.useState('');

  /** start of modal related control */
  const [isOpen, setIsOpen] = React.useState(false);
  const [showProducts, setShowProducts] = React.useState(true);
  const [showSynnexProducts, setShowSynnexProducts] = React.useState(false);

  const handleProductPreview = (tmpEpid: string) => {
    setIsOpen(true);
    setEpid(tmpEpid);
    // setSynnexKeyword(sku);
  };
  // console.log(synnexKeyword);
  const showSynnex = () => {
    setShowProducts(false);
    setShowSynnexProducts(true);
  };
  const showEbay = () => {
    setShowProducts(true);
    setShowSynnexProducts(false);
  };

  if (data?.ProductSeeds?.productSeeds?.length || newData?.QueryEbay?.response?.productSummaries?.length) {
    return (
      <>
        {/* modal for product dtails */}
        <ModalTransition>
          {isOpen && (
            <Modal
              onClose={() => (setIsOpen(false))}
            >
              <ModalHeader>
                <ModalTitle>Product info</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <ProductDetails
                  epid={epid}
                />
              </ModalBody>

              <ModalFooter>
                <Button onClick={() => (onComplete(epid))} testId="use-product-button">Use this product</Button>
                <Button onClick={() => (setIsOpen(false))}>Close</Button>
              </ModalFooter>
            </Modal>
          )}
        </ModalTransition>
        <Wrapper>
          <div className="productSearch">
            {showProducts && (
              <Flag
                icon={(<InfoIcon primaryColor={colors.B300} label="Info" />)}
                description={(
                  <>
                    {/* <p>Searching from third party data feed(For Computer Tablet &amp; Networking)</p> */}
                    <p>{t('products.searchResult.tinys')}</p>
                  </>
                )}
                id="1"
                key="1"
                title={t('products.searchResult.title')}
                actions={[
                  {
                    content: t('products.searchResult.searchNow'),
                    onClick: () => { showSynnex(); },
                  },
                ]}
              />
            )}
            {showSynnexProducts && (
              <Flag
                icon={(<InfoIcon primaryColor={colors.B300} label="Info" />)}
                id="1"
                key="1"
                title={t('products.searchResult.title')}
                actions={[
                  {
                    content: 'Switch back',
                    onClick: () => { showEbay(); },
                  },
                ]}
              />
            )}
          </div>
        </Wrapper>

        {showProducts && newData?.QueryEbay?.response?.productSummaries && (
          <>
            <Products
              productSummaries={newData?.QueryEbay?.response?.productSummaries}
              handleProductPreview={handleProductPreview}
            />
          </>
        )}
        {showSynnexProducts && data?.ProductSeeds.productSeeds && (
          <SynnexProducts
            productSummaries={data?.ProductSeeds.productSeeds}
            handleProductPreview={() => {
              if (onSelectProductCategory && data?.ProductSeeds?.productSeeds != null && data?.ProductSeeds?.productSeeds.length > 0) {
                onSelectProductCategory(data?.ProductSeeds?.productSeeds[0]);
              }
            }}
          />
        )}
      </>
    );
  }
  // return no found
  return <Nofound showProducts={showProducts} showSynnexProducts={showSynnexProducts} showSynnex={showSynnex} showEbay={showEbay} />;
};
